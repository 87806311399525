<template>
    <page-header-wrapper>
        <template slot="extra">
            <a-button @click="addName" v-action:whiteName>
                <a-icon type="file-text" />白名单管理
            </a-button>
        </template>
        <a-row :gutter="0" class="block">
            <a-col :md="8" :sm="24">
                <div class="blockList line">
                    <div>待推送任务</div>
                    <div>{{ topData && topData.unpush_count }}</div>
                </div>
            </a-col>
            <a-col :md="8" :sm="24">
                <div class="blockList line">
                    <div>累计到达人数</div>
                    <div>{{ topData && topData.push_total_count }}</div>
                </div>
            </a-col>
            <a-col :md="8" :sm="24">
                <div class="blockList">
                    <div>累计推送次数</div>
                    <div>{{ topData && topData.complete_task_count }}</div>
                </div>
            </a-col>
        </a-row>
        <a-card :bordered="false">
            <div class="topHead">
                <div>推送列表</div>
                <div class="topHead_right">
                    <div class="topHead_right_radio">
                        <a-radio-group v-model="queryParam.pushStatus" @change="getStatus">
                            <a-radio-button :value="item.id" v-for="(item, index) in statusData" :key="index">
                                {{ item.name }}
                            </a-radio-button>
                        </a-radio-group>
                    </div>
                    <div>
                        <a-input-search placeholder="请输入" style="width: 200px" @search="onSearch" />
                    </div>
                </div>
            </div>
            <div class="message_main">
                <div class="btn_add_msg" @click="addNew" v-action:add>
                    <a-icon type="plus" />添加
                </div>
                <div>
                    <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns"
                        :data="loadData" showPagination="auto" class="stable">
                        <span slot="state" slot-scope="push_status,record">
                            <a-tag color="#999" v-if="record.push_status == 1">待推送</a-tag>
                            <a-tag color="#f50" v-else-if="record.push_status == 2">推送中</a-tag>
                            <a-tag color="#87d068" v-else-if="record.push_status == 3">已完成</a-tag>
                        </span>
                        <span slot="key" slot-scope="title,record">
                            <div>{{ title }}</div>
                            <div style="color:#999">创建时间：{{ record.create_time | time }}</div>
                        </span>
                        <span slot="operation" slot-scope="operate_name">
                            {{ operate_name || '-' }}
                        </span>
                        <span slot="update_time" slot-scope="complete_time">
                            <div>推送完成时间</div>
                            <div>{{ (complete_time == 0 ? '' : complete_time || '') | time }}</div>
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <a @click="review(record)" v-action:review>查看</a>
                            <a-divider type="vertical" v-action:review />
                            <template v-if="record.push_status == 1">
                                <a @click="handlePre(record)" v-action:preview1>推送预览</a>
                                <a-divider type="vertical" v-action:preview1 />
                                <a @click="formatSend(record)" v-action:preview2 v-if="record.is_test == 1">全局推送</a>
                                <a-divider type="vertical" v-action:preview2 v-if="record.is_test == 1" />
                                <!-- <a @click="handleEdit(record)" v-action:edit>编辑</a>
                                <a-divider type="vertical" v-action:edit />
                                <a @click="handleDel(record)" v-action:del>删除</a> -->
                                <a-dropdown v-if="$auth('noticeList.edit') || $auth('noticeList.del')">
                                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                        更多
                                        <a-icon type="down" />
                                    </a>
                                    <a-menu slot="overlay">
                                        <a-menu-item v-action:edit>
                                            <a href="javascript:void(0);" @click="handleEdit(record)">编辑</a>
                                        </a-menu-item>
                                        <a-menu-item v-action:del>
                                            <a href="javascript:void(0);" @click="handleDel(record)">删除</a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </template>
                            <template v-else>
                                --
                            </template>
                        </span>
                    </s-table>
                </div>
            </div>
        </a-card>

        <div class="mask" v-show="showRes"></div>
        <div :class="showRes ? 'showRes sendDialog' : 'sendDialog'">
            <div class="icon_close" @click.stop="close">
                <a-icon type="close" />
            </div>
            <div class="sd_center">
                <div><img src="@/assets/images/sendDialog_tips.png" alt=""></div>
                <div>预览已推送</div>
                <div>如未收到可点击重发预览</div>
            </div>
            <div class="sd_button">
                <a-button @click="repeat(1)">重发预览</a-button>
                <a-button type="primary" @click="repeat(2)">正式推送</a-button>
            </div>
        </div>
        <!-- 弹窗 -->
        <a-modal :title="id ? '编辑通知' : '添加通知'" :visible="visible" :confirm-loading="confirmLoading" width="535px"
            class="modal_foot_none push_modal" :closable="false">
            <div>
                <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" @submit="handleSubmit"
                    labelAlign="left">
                    <a-form-item label="类型">
                        <a-select @change="getType"
                            v-decorator="['type', { rules: [{ required: true, message: '请选择类型!' }] }]"
                            placeholder="选择类型">
                            <a-select-option :value="item.id" v-for="(item, idx) in waterType" :key="idx">
                                {{ item.title }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <template v-if="type == 1 || type == 2">
                        <a-form-item label="停水标题">
                            <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入停水标题!' }] }]"
                                placeholder="请输入停水标题" />
                        </a-form-item>
                        <a-form-item label="停水原因">
                            <a-input v-decorator="['reason', { rules: [{ required: true, message: '请输入停水原因!' }] }]"
                                placeholder="请输入停水原因" />
                        </a-form-item>
                        <a-form-item label="停水时间">
                            <a-input v-decorator="['period', { rules: [{ required: true, message: '请输入停水时间!' }] }]"
                                placeholder="请输入停水时间" />
                        </a-form-item>
                        <a-form-item label="停水截止日期">
                            <a-date-picker v-decorator="['date', { rules: [{ required: true, message: '请选择停水日期!' }] }]"
                                placeholder="请选择停水日期" style="width:345px" />
                        </a-form-item>
                        <a-form-item label="停水区域">
                            <a-textarea rows="4"
                                v-decorator="['area', { rules: [{ required: true, message: '请输入影响单位!' }] }]"
                                placeholder="请输入影响单位" />
                        </a-form-item>
                    </template>
                    <template v-else-if="type == 3">
                        <a-form-item label="降压供水标题">
                            <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入降压供水标题!' }] }]"
                                placeholder="请输入降压供水标题" />
                        </a-form-item>
                        <a-form-item label="降压供水原因">
                            <a-input v-decorator="['reason', { rules: [{ required: true, message: '请输入降压供水原因!' }] }]"
                                placeholder="请输入降压供水原因" />
                        </a-form-item>
                        <a-form-item label="降压供水时间">
                            <a-input v-decorator="['period', { rules: [{ required: true, message: '请输入降压供水时间!' }] }]"
                                placeholder="请输入降压供水时间" />
                        </a-form-item>
                        <a-form-item label="降压供水截止日期">
                            <a-date-picker
                                v-decorator="['date', { rules: [{ required: true, message: '请选择降压供水日期!' }] }]"
                                placeholder="请选择降压供水日期" style="width:345px" />
                        </a-form-item>
                        <a-form-item label="降压供水区域">
                            <a-textarea rows="4"
                                v-decorator="['area', { rules: [{ required: true, message: '请输入影响单位!' }] }]"
                                placeholder="请输入影响单位" />
                        </a-form-item>
                    </template>
                    <template v-else-if="type == 4">
                        <a-form-item label="恢复供水标题">
                            <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入恢复供水标题!' }] }]"
                                placeholder="请输入恢复供水标题" />
                        </a-form-item>
                        <a-form-item label="恢复供水原因">
                            <a-input v-decorator="['reason', { rules: [{ required: true, message: '请输入恢复供水原因!' }] }]"
                                placeholder="请输入恢复供水原因" />
                        </a-form-item>
                        <a-form-item label="恢复供水时间">
                            <a-input v-decorator="['period', { rules: [{ required: true, message: '请输入恢复供水时间!' }] }]"
                                placeholder="请输入恢复供水时间" />
                        </a-form-item>
                        <a-form-item label="恢复供水截止日期">
                            <a-date-picker
                                v-decorator="['date', { rules: [{ required: true, message: '请选择恢复供水日期!' }] }]"
                                placeholder="请选择恢复供水日期" style="width:345px" />
                        </a-form-item>
                        <a-form-item label="恢复供水区域">
                            <a-textarea rows="4"
                                v-decorator="['area', { rules: [{ required: true, message: '请输入影响单位!' }] }]"
                                placeholder="请输入影响单位" />
                        </a-form-item>
                    </template>
                    <a-form-item label="温馨提示">
                        <a-textarea rows="4"
                            v-decorator="['remark', { rules: [{ required: true, message: '请输入温馨提示!' }] }]"
                            placeholder="请输入温馨提示" />
                    </a-form-item>
                    <a-form-item label="图片">
                        <a-upload name="file" :multiple="false" :action="imgUpload" :data="dataObj" :headers="headers"
                            @change="handleChange" v-if="!img">
                            <div class="bannerUpload">
                                <div><a-icon type="plus" style="color:#999;font-size: 30px;" /></div>
                                <div>上传</div>
                            </div>
                        </a-upload>
                        <div v-else>
                            <div class="preview">
                                <img :src="prefix + img" alt="">
                                <a-icon type="close-circle" class="icon-delete" v-show="img" @click="deleteImg" />
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 24 }" style="text-align: center;">
                        <a-button type="primary" html-type="submit">
                            确定
                        </a-button>
                        <a-button type="default" style="margin-left: 10px;" @click="reset">
                            取消
                        </a-button>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>

        <a-modal title="查看" :visible="visible_review" width="535px" class="modal_foot_none"
            @cancel="visible_review = false" id="review">
            <div>
                <div class="form_item">
                    <div class="form_item_label">标题</div>
                    <div>{{ reviewData && reviewData.title }}</div>
                </div>
                <template v-if="reviewData && reviewData.type == 1 || reviewData && reviewData.type == 2">
                    <div class="form_item">
                        <div class="form_item_label">类型</div>
                        <div>{{ getName(reviewData && reviewData.type) }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">停水原因</div>
                        <div>{{ reviewData && reviewData.reason }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">停水时间</div>
                        <div>{{ reviewData && reviewData.period }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">停水截止日期</div>
                        <div>{{ (reviewData && reviewData.date) | time }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">停水区域</div>
                        <div>{{ reviewData && reviewData.area }}</div>
                    </div>
                </template>
                <template v-if="reviewData && reviewData.type == 3">
                    <div class="form_item">
                        <div class="form_item_label">类型</div>
                        <div>{{ getName(reviewData && reviewData.type) }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">降压供水原因</div>
                        <div>{{ reviewData && reviewData.reason }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">降压供水时间</div>
                        <div>{{ reviewData && reviewData.period }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">降压供水截止日期</div>
                        <div>{{ (reviewData && reviewData.date) | time }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">降压供水区域</div>
                        <div>{{ reviewData && reviewData.area }}</div>
                    </div>
                </template>
                <template v-if="reviewData && reviewData.type == 4">
                    <div class="form_item">
                        <div class="form_item_label">类型</div>
                        <div>{{ getName(reviewData && reviewData.type) }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">恢复供水原因</div>
                        <div>{{ reviewData && reviewData.reason }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">恢复供水时间</div>
                        <div>{{ reviewData && reviewData.period }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">恢复供水截止日期</div>
                        <div>{{ (reviewData && reviewData.date) | time }}</div>
                    </div>
                    <div class="form_item">
                        <div class="form_item_label">恢复供水区域</div>
                        <div>{{ reviewData && reviewData.area }}</div>
                    </div>
                </template>
                <div class="form_item">
                    <div class="form_item_label">温馨提示</div>
                    <div>{{ reviewData && reviewData.remark }}</div>
                </div>
            </div>
        </a-modal>
        <!-- 白名单 -->
        <a-drawer title="白名单管理" placement="right" :closable="false" :visible="drawer" :get-container="false"
            width="330px" @close="onCloseDarwer" class="myDrawer">
            <div class="drawer_main">
                <div class="codeMain">
                    <!-- <img src="" alt=""> -->
                    <a-spin tip="正在生成" :spinning="spinning">
                        <vue-qr :text="url" :size="218"></vue-qr>
                    </a-spin>
                    <div class="code_tips">
                        扫码二维码绑定白名单
                        <span id="refresh" @click="getKey">[刷新]</span>
                    </div>
                </div>
                <div>
                    <a-table :columns="name_columns" :data-source="name_data" :rowKey="(record) => record.id"
                        :pagination="pagination">
                        <span slot="nickname" slot-scope="record">
                            {{ record }}
                        </span>
                        <span slot="action" slot-scope="record,id">
                            <template>
                                <a @click="deleteName(id)">删除</a>
                            </template>
                        </span>
                    </a-table>
                </div>
            </div>
        </a-drawer>
    </page-header-wrapper>
</template>

<script>
import storage from 'store';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import moment from 'moment'
var int = null
const columns = [{
    title: '',
    dataIndex: 'pushStatus',
    scopedSlots: {
        customRender: 'state'
    },
    align: 'left',
    width: '120px'
},
{
    title: '',
    dataIndex: 'title',
    scopedSlots: {
        customRender: 'key'
    },
    align: 'left'
},
{
    title: '',
    dataIndex: 'operate_name',
    scopedSlots: {
        customRender: 'operation'
    },
    align: 'center',
    width: '100px'
},
{
    title: '',
    dataIndex: 'complete_time',
    scopedSlots: {
        customRender: 'update_time'
    },
    align: 'left',
    width: '180px'
},
{
    title: '',
    dataIndex: 'ac',
    scopedSlots: {
        customRender: 'action'
    },
    align: 'center',
    width: '280px'
},
]

const name_columns = [{
    title: '昵称',
    dataIndex: 'nickname',
    scopedSlots: {
        customRender: 'name'
    },
    align: 'center'
},
{
    title: '操作',
    dataIndex: 'ac',
    scopedSlots: {
        customRender: 'action'
    },
    align: 'center'
}
]
import {
    STable,
    Ellipsis
} from '@/components'
import {
    getTsNoticeType,
    addNotice,
    getTsList,
    delNotice,
    getWhiteList,
    getKey,
    delName,
    sendMsg,
    getData
} from '@/api/notice'
import {
    Modal,
    message
} from 'ant-design-vue'
import {
    prefix,
    imgUpload,
    decrypt
} from '@/utils'
import vueQr from 'vue-qr'
export default {
    name: '',
    components: {
        STable,
        Ellipsis,
        vueQr
    },
    data() {
        this.columns = columns
        this.name_columns = name_columns
        return {
            imgUpload: imgUpload,
            statusData: [{
                id: '0',
                name: '全部'
            },
            {
                id: '1',
                name: '待推送'
            },
            {
                id: '2',
                name: '推送中'
            },
            {
                id: '3',
                name: '已完成'
            },
            ],
            status: '0',
            queryParam: {
                pushStatus: '0',
                key: ''
            },
            loadData: parameter => {
                const requestParameters = Object.assign({}, parameter, this.queryParam)
                return getTsList(requestParameters)
                    .then(res => {
                        return res.data
                    })
            },
            visible: false,
            confirmLoading: false,
            form: this.$form.createForm(this),
            waterType: [], //停水类型
            id: '', //通知id
            drawer: false, //白名单
            name_data: [], //白名单列表
            key: '', //二维码key
            url: '', //二维码url
            spinning: false, //生成二维码等待
            showRes: false,
            sendId: '', //推送id
            topData: '',
            visible_review: false,
            reviewData: '',
            prefix: prefix,
            type: '1', //停水类型
            pagination: false,
            img: '',
            dataObj: {

            },
            headers: {
                token: storage.get(ACCESS_TOKEN)
            },
        }
    },
    created() {
        this.getData()
        this.getTsType()
    },
    methods: {
        getType(e) {
            //console.log('type',e);
            this.type = e;
        },
        onSearch(value) { //关键词搜索
            //console.log(value)
            this.queryParam.key = value
            this.$refs.table.refresh(true)
        },
        getStatus(e) {
            //console.log(e.target.value)
            this.queryParam.status = e.target.value
            this.$refs.table.refresh(true)
        },
        addNew() { //新增
            this.$nextTick(() => {
                this.type = this.form.getFieldValue('type') || '1';
            })
            this.visible = true
        },
        handleOk(e) {
            this.visible = false
        },
        handleCancel(e) {
            this.visible = false
        },
        handleSubmit(e) {
            //console.log(1)
            e.preventDefault();
            //console.log(2)
            let that = this
            this.form.validateFields((err, values) => {
                //console.log('err',err,'values',values,values.date.format('YYYY-MM-DD'))
                if (!err) {
                    //console.log('Received values of form: ', values);
                    let params = {
                        id: that.id,
                        title: values.title,
                        area: values.area,
                        period: values.period,
                        date: values.date.format('YYYY-MM-DD'),
                        reason: values.reason,
                        type: values.type,
                        remark: values.remark,
                        img: this.img
                    }

                    addNotice(params).then(res => {
                        if (res.code == 200) {
                            that.$message.success(that.id ? '编辑成功' : '添加成功')
                            that.visible = false
                            that.$refs.table.refresh(true)
                            this.reset()
                        } else {
                            that.$message.success(res.msg)
                            setTimeout(_ => {
                                that.visible = false
                            }, 600)
                        }
                    })
                } else {

                }
            });
        },
        handleSelectChange(value) {
            console.log(value);
        },
        handleEdit(data) { //编辑
            this.$nextTick(() => {
                this.type = this.form.getFieldValue('type') || '1';
            })
            this.id = data.id
            this.visible = true
            this.$nextTick(() => {
                this.form.setFieldsValue({
                    "title": data.title,
                    "area": data.area,
                    "date": moment(Number(data.date) * 1000),
                    "reason": data.reason,
                    "type": data.type,
                    "period": data.period,
                    "remark": data.remark
                })
                this.img = data.img;
            })
        },
        review(data) { //查看
            this.reviewData = data;
            this.visible_review = true;
        },
        handlePre(data) { //预览
            let that = this
            this.sendId = data.id
            this.$confirm({
                title: '预览',
                content: '确认推送预览吗?',
                onOk() {
                    //console.log('send')
                    let params = {
                        id: data.id,
                        type: 1
                    }
                    sendMsg(params).then(res => {
                        if (res.code == 200) {
                            that.showRes = true
                            that.$refs.table.refresh(true)
                        } else {
                            that.$message.error(res.msg)
                        }
                    }).catch(res => {
                        console.log(res.msg)
                    })
                },
                onCancel() {

                }
            })
        },
        formatSend(data) { //全局推送
            this.sendId = data.id;
            this.repeat(2)
        },
        repeat(type) { //1 重发预览  2 全局推送
            let that = this
            this.$confirm({
                title: type == 1 ? '重发预览' : '全局推送',
                content: type == 1 ? '确认重发吗?' : '确认全局推送吗?',
                onOk() {
                    let params = {
                        id: that.sendId,
                        type: type
                    }
                    sendMsg(params).then(res => {
                        if (res.code == 200) {
                            if (type == 2) {
                                that.showRes = false
                            }
                            that.$message.success(res.msg)
                        } else {
                            that.$message.error(res.msg)
                        }
                    }).catch(res => {
                        console.log(res.msg)
                    })

                },
                onCancel() {

                }
            })
        },
        close() {
            this.showRes = false
        },
        handleDel(data) { //删除通知
            let that = this
            Modal.confirm({
                content: '确认删除吗?',
                onOk() {
                    let params = {
                        id: data.id
                    }
                    delNotice(params).then(res => {
                        if (res.code == 200) {
                            that.$message.success('删除成功')
                            that.$refs.table.refresh(true)
                        }
                    })
                },
                onCancel() {

                }
            })
        },
        getData() {
            getData({}).then(res => {
                if (res.code == 200) {
                    this.topData = res.data;
                } else {

                }
            }).catch(res => { })
        },
        reset() {
            this.id = ''
            this.visible = false
            this.$nextTick(() => {
                this.form.setFieldsValue({
                    "title": '',
                    "type": '1',
                    "area": '',
                    "date": null,
                    "reason": '',
                    "period": '',
                    "remark": ''
                })
                this.img = '';
            })
        },
        getTsType() {
            let params = {}
            getTsNoticeType(params).then(res => {
                if (res.code == 200) {
                    this.waterType = res.msg
                }
            })
        },
        getName(id) {
            let type = this.waterType;
            let typeName= '';
            type.map((item, index) => {
                if (item.id == id) {
                    typeName =  item.title
                }
            })
            return typeName;
        },
        addName() { //添加白名单
            this.drawer = true
            this.getWhiteList()
            this.getKey()
            this.getBindStatus()
        },
        onCloseDarwer() {
            this.drawer = false
            clearInterval(int)
        },
        getWhiteList() {
            let params = {
                pageIndex: 1,
                pageSize: 50
            }
            getWhiteList(params).then(res => {
                //console.log('res',res)
                if (res.code == 200) {
                    this.name_data = res.data.list
                }
            })
        },
        getKey() { //获取key
            this.spinning = true
            let params = {}
            getKey(params).then(res => {
                if (res.code == 200) {
                    this.key = res.msg
                    this.url = this.prefix + 'newfxgs/newAdmin.php?c=tsnotice&a=wxAuth&key=' + res.msg
                    console.log(this.url);
                    this.spinning = false
                }
            })
        },
        deleteName(data) { //删除白名单
            console.log('data', data);
            let that = this
            Modal.confirm({
                content: '确认删除吗?',
                onOk() {
                    let params = {
                        id: data.id
                    }
                    delName(params).then(res => {
                        if (res.code == 200) {
                            that.$message.success('删除成功')
                            that.getWhiteList()
                        }
                    })
                },
                onCancel() {

                }
            })
        },
        getBindStatus() {
            int = setInterval(_ => {
                console.log('loading...')
                this.getWhiteList()
            }, 2000)
        },
        handleChange(info) {
            const status = info.file.status;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status == "removed") {
                this.img = ''
            }
            if (status === 'done') {
                console.log(info.file.response.code);
                if (info.file.response.code == 0) {
                    let enData = info.file.response.data;
                    //enData = decrypt(enData);
                    this.img = enData.path;
                } else if (info.file.response.code == -98) {
                    this.$message.error(`登录已过期,请重新登录`);
                    setTimeout(_ => {
                        localStorage.clear();
                        location.reload();
                    }, 1200)
                }
            } else if (status === 'error') {
                this.$message.error(`${info.file.name} 上传失败.`);
            }
        },
        deleteImg() {  //删除上传图片
            this.img = ''
        }
    },
    filters: {
        time(t) {
            if (t) {
                return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
            } else {
                return '-'
            }
        },
    }
}
</script>
<style>
#review .ant-modal-body {
    max-height: 600px;
    overflow-y: auto;
}
</style>

<style scoped>
.block {
    background: #fff;
    margin-bottom: 22px;
    padding: 24px 0;
    border-radius: 2px;
}

.blockList {
    text-align: center;
    position: relative;
}

.blockList div:nth-child(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
}

.blockList div:nth-child(2) {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 4px;
}

.line:after {
    display: block;
    content: '';
    width: 1px;
    height: 58px;
    background: #e8e8e8;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.topHead {
    display: flex;
    justify-content: space-between;
}

.topHead_right {
    display: flex;
}

.topHead_right_radio {
    margin-right: 25px;
}

.btn_add_msg {
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px dashed rgba(217, 217, 217, 1);
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
}

.btn_add_msg i {
    margin-right: 10px;
    margin-top: -1px;
}

.codeMain {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.codeMain img {
    width: 218px;
    height: 218px;
    background: rgba(216, 216, 216, 1);
    margin-bottom: 20px;
}

.code_tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 30px;
}

#refresh {
    color: #0091FF;
    cursor: pointer;
}

.sendDialog {
    width: 294px;
    height: 224px;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    border: 1px solid rgba(151, 151, 151, 1);
    position: fixed;
    top: -150%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    padding: 15px;
    transition: .36s ease all;
}

.showRes {
    top: 50% !important;
    transition: .36s ease all;
}

.icon_close {
    text-align: right;
}

.icon_close i {
    color: #999;
    cursor: pointer;
}

.sd_center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sd_center img {
    width: 55px;
}

.sd_center div:nth-child(2) {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 22px;
}

.sd_center div:nth-child(3) {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 20px;
    margin-top: 11px;
}

.sd_button {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.sd_button>>>.ant-btn {
    width: 126px;
    height: 40px;
}

.form_item {
    display: flex;
    margin-bottom: 10px;
}

.form_item_label {
    min-width: 125px;
}

.bannerUpload {
    width: 88px;
    height: 88px;
    background: rgba(245, 245, 245, 1);
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.preview {
    position: relative;
    z-index: 0;
    max-width: 120px;
    text-align: center;
}

.preview img {
    height: 60px;
    border-radius: 2px;
    cursor: pointer;
}

.icon-delete {
    color: #FF3B30;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 20px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.push_modal /deep/ .ant-modal-body {
    padding: 14px 24px 4px 24px;
}
</style>
